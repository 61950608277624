<template>
  <default-layout>
    <v-img src="images/boxes2.jpeg"></v-img>
    <div class="d-flex align-center flex-column justify-center my-5 py5">
      <h1 class="gold--text my-5">ช่องทางการติดต่อ</h1>
      <v-card class="d-flex flex-column flex-md-row align-center">
        <a href="https://lin.ee/CZBqtMB">
          <v-img src="images/line-ak55.png" width="300px"></v-img>
        </a>

        <v-card-text>
          <v-img src="images/line.jpg" width="300px"></v-img>
        </v-card-text>
      </v-card>
    </div>

  </default-layout>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {}
}
</script>

<style>

</style>
